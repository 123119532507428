
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































.flexible-highlight-duo {
  @extend %shadow;
  @extend %border-radius-small;

  position: relative;
  overflow: hidden;

  @include mq(m) {
    display: flex;
    justify-content: space-between;
  }
}

.flexible-highlight-duo__title {
  @extend %text-center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $spacing * 2;
  background: $c-mustard;

  h2 {
    @include fluid(
      font-size,
      (
        m: 30px,
        xxl: 50px,
      )
    );
    @include fluid(
      line-height,
      (
        m: 40px,
        xxl: 45px,
      )
    );

    color: $c-white;
  }

  @include mq(m) {
    width: col(5, 12);
  }
}

.flexible-highlight-duo__title__picto {
  @extend %ff-alt;
  @extend %fw-medium;
  @include fluid(
    font-size,
    (
      xxs: 32px,
      xxl: 48px,
    )
  );
  @include fluid(
    line-height,
    (
      xxs: 60px,
      xxl: 95px,
    )
  );
  @include fluid(
    width,
    (
      xxs: 60px,
      xxl: 95px,
    )
  );

  margin: 0 auto $spacing;
  color: $c-mustard;
  background: $c-white;
  border-radius: 50%;
}

.flexible-highlight-duo__text ::v-deep {
  padding: $spacing * 1.25;

  li {
    padding-left: $spacing;

    &::before {
      top: 0.6em;
      width: 1.1rem;
      height: 0.7rem;
      margin: 0;
      background: url('../../assets/images/checklist.png');
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 0;
    }
  }

  /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
  li + li {
    margin-top: 0;
  }

  .small {
    position: relative;
    padding-left: $spacing;

    &::before {
      position: absolute;
      left: 0;
      content: '*';
      color: $c-mustard;
    }
  }

  p,
  ul,
  .small {
    margin: $spacing 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mq(s) {
    flex: 1;
  }

  @include mq(l) {
    padding: $spacing * 2.5;

    p,
    ul,
    .small {
      margin: $spacing * 1.5 0;
    }
  }
}

.flexible-highlight-duo__text__link {
  margin-top: $spacing * 1.5;

  &::after {
    @include get-all-space;

    content: '';
  }
}
